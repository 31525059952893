import { CompCardDetail } from "../../../store/model/actorGroupModel";
import Card from "react-bootstrap/Card";
import CompCardDetailTable from "./CompCardDetailTable";
import config from "../../../config";
import { toJpeg } from "html-to-image";
import { Buffer } from "buffer";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Download from "@mui/icons-material/Download";

interface ActorCompCardProps {
    actor: CompCardDetail;
    handleClick?: () => void;
    exportMode?: boolean;
}

function openBase64InNewTab(data: string, mimeType: string, fileName: string) {
    var byteArr = new Uint8Array(Buffer.from(data.replace("data:image/jpeg;base64,", ""), 'base64'));                

    //create a blob using the buffer above
    var file = new Blob([byteArr], {
      type: mimeType,          
    });
    
  
    var fileURL = URL.createObjectURL(file);    
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);  
    // window.open(fileURL, "_blank");
}

const ActorCompCard: React.FC<ActorCompCardProps> = ({ actor, handleClick, exportMode = false}) => {
    const handleExportClick = () => {
        toJpeg(document.getElementById("compcard"+actor.actorId) as HTMLElement, { quality: 0.95 })
        .then(function (dataUrl) {
            openBase64InNewTab(dataUrl, "data:image/jpeg", actor.fullName + ".jpg");
        })
    }
    return (
        <Box sx={{ position: "relative"}}>
        {exportMode && (<Fab sx={{ position: "absolute", top: "-8px", left: "-8px"}} color="primary" onClick={handleExportClick}><Download /></Fab>)}
        <Card id={"compcard" + actor.actorId} onClick={() => handleClick ? handleClick() : null}>
            <Card.Img
                loading="lazy"
                src={                    
                    config.api.BASE_URL + "/actor/image/" + actor.primaryImageId + "/false"
                }
                placeholder={
                    require("../../../assets/files/headshot-placeholder.svg")
                        .default
                }
                style={{
                    width: "100%",
                    objectFit: "fill",
                }}
            />
            <Card.Body style={{ padding: "0" }}>
                <CompCardDetailTable actor={actor} />
            </Card.Body>
        </Card>
        </Box>
    );
};

export default ActorCompCard;
