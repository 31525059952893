import {
    Page,
    View,
    Document,
    StyleSheet,    
    Image,
    Text,
} from "@react-pdf/renderer";
import { CompCardDetail } from "../../../store/model/actorGroupModel";
import { chunks } from "../../../utility/array";
import PDFTableRow from "./PDFTableRow";
import { MetricToImperialString } from "../../../utility/measurement";
import config from "../../../config";

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        flexWrap: "wrap",
        padding: 10,
    },
    section: {
        width: "46%",  
        height: "auto",             
        display: "flex",
        flexDirection: "row",
        margin: 10,
        padding: 3,
        flexGrow: 0,
        fontSize: "16px",
        border: "1px solid #000",
    },
    profileImage: {
        height: "150px",
        objectFit: "cover",
    },
    skillRow: {
        flexDirection: "row",
        alignItems: "flex-start",
        flexWrap: "wrap",
        fontSize: "10",
    },
    skillDescription: {
        width: "39%",
        fontWeight: "bold",
        backgroundColor: "#e4e4e4",
        marginRight: "1%",
        height: "100%",
    },
    skillValue: {
        width: "45%",
        maxWidth: "45%",
        fontWeight: "normal",
    },
});

interface CompCardPDFProps {
    actors: CompCardDetail[];
    exportCode: string;
}

const CompCardPDF: React.FC<CompCardPDFProps> = ({ actors, exportCode }) => (
    <Document title={exportCode}>
        {actors &&
            chunks(actors, 4).map((arr, index) => (
                <Page key={index} style={styles.page} orientation="landscape">
                    {arr.map((x) => {
                        return (
                            <View style={styles.section}>
                                <Image
                                    src={ config.api.BASE_URL +
                                        "/actor/image/" +
                                        x.primaryImageId +
                                        "/false"}
                                    style={styles.profileImage}
                                />
                                <View>
                                    <PDFTableRow
                                        title="Name"
                                        value={x.fullName}
                                    />
                                    <PDFTableRow
                                        title="Date of Birth"
                                        value={new Date(
                                            x.dateOfBirth
                                        ).toLocaleDateString("en-AU")}
                                    />
                                    <PDFTableRow
                                        title="Height"
                                        value={MetricToImperialString(x.height, false, true)}
                                    />
                                    <PDFTableRow
                                        title="Eye Colour"
                                        value={x.eyeColour}
                                    />
                                    <PDFTableRow
                                        title="Hair Colour"
                                        value={x.hairColour}
                                    />
                                    <PDFTableRow
                                        title="Nationality"
                                        value={x.nationality}
                                    />       
                                    {x.fluentIn &&
                                        <PDFTableRow
                                            title="Fluent In"
                                            value={x.fluentIn.join(", ")}
                                        />
                                    
                                    }                         
                                    
                                    <PDFTableRow
                                        title="Size"
                                        value={
                                            (x.bust ? "C:" + x.bust : "") +
                                            " " +
                                            "W:" +
                                            (x.waist) +
                                            " " +
                                            (x.hips && x.hips > 0 ? "H: " + x.hips : "")
                                        }
                                        underlined={true}
                                    />
                                    <PDFTableRow
                                        title="Shoe:"
                                        value={x.shoeSize}
                                    />
                                    {x.dressSize && (
                                        <PDFTableRow
                                            title="Dress:"
                                            value={
                                                x.dressSize.sizeStart +
                                                "/" +
                                                x.dressSize.sizeEnd
                                            }
                                        />
                                    )}
                                    <PDFTableRow
                                        title="Profession:"
                                        value={x.professions && x.professions.length > 0 ? x.professions[0] : ""}
                                    />                                    
                                </View>
                            </View>
                        );
                    })}
                </Page>
            ))}
    </Document>
);

export default CompCardPDF;
