import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getCompCard } from "../../../store/actions/actorGroupActions";
import {
    compCardSelector,
    compCardsLoadingSelector,
} from "../../../store/selectors/actorGroupSelector";

import PageContainer from "../../layout/PageContainer";
import Container from "../../layout/Container";
import Button from "react-bootstrap/Button";
import ActorCompCard from "../../elements/CompCard/ActorCompCard";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import { useSearchParams } from "react-router-dom";
import PrinterFriendlyActorCompCard from "../../elements/CompCard/PrinterFriendlyActorCompCard";
import CompCardPDF from "../../elements/CompCard/CompCardPDF";
import { BlobProvider } from "@react-pdf/renderer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useWindowDimension } from "../../../utility/windowSize";

const PrinterFriendlyContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 1%;
    padding: 2rem;
`;

const PrinterPageBreak = styled.div`
    display: none;

    @media print and (orientation: landscape) {
        display: block;
        page-break-before: always;
        width: 100%;
        height: 230px;
    }

    @media print and (orientation: portrait) {
        display: block;
        page-break-before: always;
        width: 100%;
        height: 350px;
    }
`;
interface CompCardPageProps {
    exportCode: string;
}

const CompCardPage: React.FC<CompCardPageProps> = ({ exportCode }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [queryParams] = useSearchParams();
    const isPrinterFriendly = queryParams.get("p") === "1";
    const selectedActorId = queryParams.get("a");
    const compCardDetails = useAppSelector((state) =>
        compCardSelector(state, exportCode)
    );
    const dimensions = useWindowDimension(100);
    const [exportMode, setExportMode] = useState(false);

    const selectedActor = compCardDetails
        ? compCardDetails.find((x) => x.actorId + "" === selectedActorId)
        : null;
    const isLoading = useAppSelector(compCardsLoadingSelector);

    const showSelectedActor = (actorId: number) => {
        if(dimensions.width < theme.breakpoints.values.lg){
            return;
        }
        window.open(
            window.location.origin +
                "/role/" +
                exportCode +
                "?p=1&a=" +
                actorId,
            "_self"
        );
    };

    useEffect(() => {
        dispatch(getCompCard({ exportCode }));
    }, [exportCode, dispatch]);

    return (
        <PageContainer>
            <Container style={{ maxWidth: "1200px" }}>
                {!isLoading && !isPrinterFriendly && (
                    <>
                        <Box sx={{ 
                            [theme.breakpoints.down("lg")]: {
                                display: "none"
                            }
                        }}>
                        <div className="p-3 d-flex justify-content-center gap-3">
                            <Button
                                className="p-3"
                                variant="primary"
                                style={{ color: "#FFF", minWidth: "30%" }}
                                onClick={(e) =>
                                    window.open(
                                        window.location.origin +
                                            "/role/" +
                                            exportCode +
                                            "?p=1",
                                        "_blank"
                                    )
                                }
                            >
                                Click here for printer friendly version
                            </Button>
                            <BlobProvider
                                document={
                                    <CompCardPDF
                                        actors={compCardDetails}
                                        exportCode={exportCode}
                                    />
                                }
                            >
                                {({ url, loading, error }) => {
                                    return (
                                        <Button
                                            className="p-3"
                                            variant="primary"
                                            style={{
                                                color: "#FFF",
                                                minWidth: "30%",
                                            }}
                                            href={url ?? undefined}
                                        >
                                            View as PDF
                                        </Button>
                                    );
                                }}
                            </BlobProvider>
                            <Button
                             className="p-3"
                             variant="primary"
                             style={{ color: "#FFF", minWidth: "30%" }}
                             onClick={(e) =>
                                 setExportMode(!exportMode)
                             }>
                                {(exportMode ? "Disable ":"Enable ")} Export as Photos
                            </Button>
                        </div>
                        </Box>
                        <Grid container spacing={4} padding={2}>                            
                            {compCardDetails &&
                                compCardDetails.map((x) => (
                                    <Grid item sm={12} md={6} lg={3}>
                                    <ActorCompCard 
                                        key={x.actorId}
                                        actor={x} 
                                        exportMode={exportMode}
                                        handleClick={() =>
                                            showSelectedActor(x.actorId)
                                        }
                                    />
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                )}
                {!isLoading && isPrinterFriendly && (
                    <>
                        {!selectedActorId && (
                            <PrinterFriendlyContainer>
                                {compCardDetails &&
                                    compCardDetails.map((x, index) => (
                                        <>
                                            <PrinterFriendlyActorCompCard
                                                actor={x}
                                                handleClick={() =>
                                                    showSelectedActor(x.actorId)
                                                }
                                            />
                                            {(index + 1) % 4 === 0 &&
                                                index <
                                                    compCardDetails.length -
                                                        4 && (
                                                    <PrinterPageBreak>
                                                        &nbsp;
                                                    </PrinterPageBreak>
                                                )}
                                        </>
                                    ))}
                            </PrinterFriendlyContainer>
                        )}
                        {selectedActorId && selectedActor && (
                            <PrinterFriendlyContainer>
                                <PrinterFriendlyActorCompCard
                                    actor={selectedActor}
                                    isSelectedActor={true}
                                />
                            </PrinterFriendlyContainer>
                        )}
                    </>
                )}
                {isLoading && (
                    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
                        <LoadingSpinner />
                    </div>
                )}
            </Container>
        </PageContainer>
    );
};

export default CompCardPage;
